
/*Script cambio de imagenes en la galeria  */
const divGaleria = document.getElementsByClassName('bannerIMG_div')
var contador = 0;
function cambioIMG(){
    console.log(divGaleria)
    if(contador == 0){
        divGaleria[0].classList.add("activo")
        divGaleria[2].classList.remove("activo")
    }
    else if(contador == 1){
        divGaleria[1].classList.add("activo")
        divGaleria[0].classList.remove("activo")
    }
    else{
        divGaleria[2].classList.add("activo")
        divGaleria[1].classList.remove("activo")
    }
    contador++
    if(contador == 3){
        contador = 0
    }
}
setInterval(cambioIMG, 5000);
/*Script para mostrar formulario*/
const botonFormulario = document.querySelectorAll(".botonFormulario")
const contenedorFormulario = document.getElementsByClassName("formulario-contenendor")[0]
const cerrarFormulario = document.getElementsByClassName("cerrar")[0]
const fondo = document.getElementsByClassName("fondo")[0]
botonFormulario.forEach((element) => {
    element.addEventListener("click", function (e) {
        formulario.style.display = 'flex'
        console.log('que pedo')
    });
});
fondo.addEventListener("click", function (e) {
    formulario.style.display = 'none'
});
cerrarFormulario.addEventListener("click", function (e) {
    formulario.style.display = 'none'
});
